import React from 'react';
import logo from './images/logo.svg';
import './App.css';

const App = () => {
  return (
    <div className="container">
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
          PARKCASTLE
          </p>
        </header>
        <header className="App-header">
          <p>
          Website Coming Soon.
          </p>
        </header>
      </div>
    </div>
  );
}

export default App;
